// extracted by mini-css-extract-plugin
export var brand = "plus-module--brand--0db34";
export var check = "plus-module--check--6fdaa";
export var checklist = "plus-module--checklist--85ea5";
export var form = "plus-module--form--ecbff";
export var inquire = "plus-module--inquire--2197f";
export var plusPage = "plus-module--plus-page--a6b82";
export var testimonialBy = "plus-module--testimonial-by--0b7f7";
export var testimonialLogo = "plus-module--testimonial-logo--6be4a";
export var testimonialName = "plus-module--testimonial-name--95d07";
export var testimonialScreen = "plus-module--testimonial-screen--c6d66";