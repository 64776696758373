import * as styles from './plus.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { Script, graphql } from 'gatsby';
import { faArrowRight, faCheck } from '@fortawesome/pro-regular-svg-icons';

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Brand from '../components/brand';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import { LogoPlus } from '../components/logos';
import SEO from '../components/seo';
import Section from '../components/section';

const PlusPage = ({ data }) => {
  
  const initHubspotForm = () => {
    if ('hbspt' in window) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "8853169",
        formId: "2e22f1c1-2270-465a-b553-ba6732cebacb",
        target: '#hs-inquiry-form'
      });
    } else {
      setTimeout(initHubspotForm, 500);
    }
  }

  useEffect(() => {
    initHubspotForm();
  }, []);

  return (
    <>
      <SEO
        title="Interact Plus"
        description="Elevate your brand with Interact Plus: Expert-led quiz strategy and implementation for high-growth companies. Boost engagement, leads, and conversions with our tailored quiz solutions."
      />
      <Header transparent={ true } />
      <main className={styles.plusPage}>
        <Section theme="dark" style={ { paddingTop: '9.5rem' } }>
          <div className="row justify-content-center align-items-center">
            <div className="col-auto">
              <div style={{ marginBottom: '2rem' }}>
                <LogoPlus color="white" />
              </div>
            </div>
            <div className="col-12 col-lg-12 text-center">
              <h1 className="heading-xl color-white">Full service quiz strategy + implementation for scaled brands</h1>
            </div>
            <div className="col-12 col-lg-6 text-center">
              <h2 className="heading-sm">Work directly with a quiz expert from Interact to create and promote quizzes for your business. Starts at $6k/year.</h2>
              <div className="d-flex justify-content-center" style={ { marginTop: `3rem` } }>
                <AnchorLink
                  to="/plus/#inquire"
                  className={styles.inquire}
                >
                  Inquire now <FontAwesomeIcon icon={ faArrowRight } style={{ marginLeft: `0.75rem` }}/>
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center" style={{ marginTop: '5rem'}}>
            <div className="col-lg-12 col-xl-10">
              <div className="row">
                <div className="col">
                  <p className="text-body text-center" style={{ margin: '0', color: '#dfe3ff' }}>Trusted by leading businesses</p>
                </div>
              </div>
              <div className="row align-items-center justify-content-center justify-content-lg-between">
                <Brand id="mamastefit" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="jennakutcher-color" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="henryshouseofcoffee" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="gretchen-rubin" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="atlassian" className={ `col-auto ${ styles.brand }` }/>
                <Brand id="amy-porterfield" className={ `col-auto ${ styles.brand }` }/>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row justify-content-between align-items-start">
            <div className="col-12 col-lg-5 text-md-center">
              <h3 id="inquire" className="heading-xl color-black">Get started today</h3>
              <p className="heading-sm color-muted-foreground" style={{ marginBottom: '2rem' }}>Elevate your quiz marketing strategy with personalized guidance from our expert team. We'll help you create engaging quizzes, optimize your funnels, and drive measurable results for your business.</p>
              <p className="heading-sm color-black"><strong>What we offer:</strong></p>
              <ul className={ styles.checklist } style={{ alignItems: 'flex-start' }}>
                <li>
                  <span className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></span>
                  <p className="text-body color-black">Our team collaborates with you to craft innovative quiz concepts and design high-converting funnel strategies that optimize your ROI.</p>
                </li>
                <li>
                  <span className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></span>
                  <p className="text-body color-black">Our team of seasoned experts will collaborate closely with you throughout the entire quiz development process, leveraging data-driven insights and industry best practices to optimize performance and drive measurable results.</p>
                </li>
                <li>
                  <span className={ styles.check }><FontAwesomeIcon icon={ faCheck }/></span>
                  <p className="text-body color-black">Our team will optimize your quiz promotions, ad campaigns, and landing pages to maximize conversions and drive user engagement across all channels.</p>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6">
              <div id="hs-inquiry-form" className={ styles.form }></div>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={{ background: `linear-gradient(135deg, rgba(169,135,255,1) 0%, rgba(115,113,252,1) 100%)`, overflow: 'hidden' }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-8 text-center">
              <p className="heading-xs color-white" style={{ marginBottom: '1rem', textTransform: 'uppercase', letterSpacing: '1px', fontWeight: '500' }}>Client testimonial</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className={styles.testimonialLogo}>
                <Brand id="gretchen-rubin"/>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-8 text-center">
              <p className="heading-sm color-white"><em>&ldquo;Working with the Interact Plus team is so powerful because they understand our long term goals to keep building out discoverability though multiple quizzes that bring to life all the content we have on site. The quizzes we have on site continuously help us grow our audience year over year. The higher quality the quiz and the more strategic thought put in with this team means we are making a powerful growth investment every time we partner.&rdquo;</em></p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className={styles.testimonialBy}>
                <GatsbyImage image={ getImage(data.testimonialImage) } alt="Anne Mercogliano" style={ { borderRadius: `50%` } }/>
                <div className={styles.testimonialName}>
                  <p className="heading-sm color-white">Anne Mercogliano</p>
                  <p className="heading-xs color-white">CEO, Gretchen Rubin Media</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className={styles.testimonialScreen}>
                <GatsbyImage image={ getImage(data.screenshotImage) } alt="Gretchen Rubin quiz screenshot"/>
              </div>
            </div>
          </div>
        </Section>
      </main>
      <Footer/>
      <Script src="//js.hsforms.net/forms/embed/v2.js" />
    </>
  )
}

export default PlusPage

export const query = graphql`
  query {
    testimonialImage: file(relativePath: { eq: "testimonials/testimonials-10.png" }) {
      childImageSharp {
        gatsbyImageData (
          layout: FIXED,
          width: 100,
          height: 100,
          quality: 100,
        )
      }
    },
    screenshotImage: file(relativePath: { eq: "case-studies/gretchen-rubin.png" }) {
      childImageSharp {
        gatsbyImageData (
          layout: CONSTRAINED,
          width: 1000,
          quality: 100,
        )
      }
    }
  }
`